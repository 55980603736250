// import {AiOutlineHome} from 'react-icons/ai'
// import {AiOutlineSearch} from 'react-icons/ai'
// import { Link } from 'react-router-dom'
// // import { useSelector } from 'react-redux';

// import SearchBox from './SearchBox'
// const Footer=()=>{
//     // const state = useSelector((state) => state);
  
//     return(
//         <div className="bg-gradient-to-r from-indigo-400 to-cyan-400 h-[120px] flex flex-cols justify-center items-center">
//             <Link to='/'>
//   <div className='flex flex-col justify-center items-center mr-48 mt-2 text-white font-sans'>
//     <AiOutlineHome size={25}/>
//     <span>Home</span>
//   </div>
//   </Link>
//   <Link to="/search" element={<SearchBox/>}>
//   <div className='flex flex-col justify-center items-center mt-2 text-white'>
//     <AiOutlineSearch size={25} />
//     <span >Search</span>
//   </div>
//   </Link>
// </div> 
//     ) 
// }
// export default Footer
import React from 'react'

function Footer() {
  return (
    <div className='bg-gray-900 flex justify-center items-center py-4'>
        <h1 className='text-white'>© Trickso-2025.All rights reserved.</h1>
      
    </div>
  )
}

export default Footer
