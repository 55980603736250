import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import classes from './Carousel.module.css'

// import './styles.css';

// import required modules
import { EffectFade, Navigation, Pagination,Autoplay } from 'swiper/modules';


const Carousel = ({data}) => {

    console.log(data)


  return (
    <div className={classes.carousel_main}>
       <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        fadeEffect={{
          crossFade: true, // Optional: enables smooth cross fading
        }}
        speed={2500}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
       {
        data?.map((item,idx)=>(
            <>
            <SwiperSlide key={idx}>
                <img src={item.imgurlnew} className={classes.img}/>
            </SwiperSlide>
            </>
        ))
       }
      </Swiper>
    </div>
  )
}

export default Carousel
