import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGames } from './store/Slice';
import Header from './Header';
import GameCarousel from './GameCarousel';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner'
// import spinner from '../../src/assets/images/spinner.gif';
import Lottie from 'react-lottie';
import * as animationData from '../assets/images/Animation - 1709102638435.json';
import * as animationDataa from '../assets/images/Animation - 1709103850899.json';
import Footer from './Footer';
import Carousel from './Carousel';

function HomePage() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [showMore, setShowMore] = useState(false);
  const [carouselData,setCarouselData] = useState([])
  
  useEffect(() => {
    dispatch(fetchGames());
  }, [dispatch]);



  useEffect(()=>{
    let data = state.game.data.games;
    console.log(data)
    data = Object.values(data).flat();
    
       const randomObjects = data
    .sort(() => 0.5 - Math.random()) // Shuffle the array
    .slice(0, 5);
    console.log(randomObjects)
     setCarouselData(randomObjects)
    
  },[state])

  if (state.game.isLoading) {
    return (
    
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
        <Spinner/>
      </div>
     
    );
  }

  // Group games by category
  const gamesByCategory = {};
  state.game.data.games.forEach((game) => {
    if (!gamesByCategory[game.category]) {
      gamesByCategory[game.category] = [];
    }
    gamesByCategory[game.category].push(game);
  });

  const categories = state.game.data.category?.map((cat) => cat.cat_name);

  const initialCategories = categories?.slice(0, 3);

  const categoriesToShow = showMore ? categories : initialCategories;

  
  const defaultOptionss = {
    loop: true,
    autoplay: true,
    animationData: animationDataa.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  return (
    <div className=" flex flex-col overflow-hidden">
       
      <Header category={state.game.data.category} />


     <div className='main'>
     <div className='divide1'>
    <img src="/lidoma.png" alt="" className='logo h-[10rem]' />
        <div className='lines'>
        <div className='line1'>

        </div>
        <div className='circle'></div>
        <div className='line2'>

        </div>
        </div>
    </div>

    <Carousel data={carouselData}/>
     </div>



      
      <div className="flex-grow overflow-y-auto bg-black">
      
       
        <GameCarousel games={state.game.data.games} />

        {/* Display categories */}
        {categoriesToShow?.map((categoryName) => {
          const trimmedCategoryName = categoryName.trim();
        
          return (
            <div key={trimmedCategoryName} className="bg-black lg:p-9 md:p-5 sm:p-4 p-3">
              
              <h1 className="text-2xl lg:p-0 p-2 lg:px-4 lg:py-3 text-white border-b border-gray-700 bg-[#f4904d] rounded-lg font-playfair relative">
                {trimmedCategoryName} Games
              </h1>
              <div className="lg:mx-6 lg:mt-8 grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 lg:gap-8">
                {gamesByCategory[trimmedCategoryName]?.slice(0,4).map((game) => (
                  <div key={game.gameid} className={`relative ${game.category} group `}>
                    <div className=' mt-6  transition-transform duration-300 transform scale-100 group-hover:scale-105'>
                    <img 
                      className="rounded-t object-full rounded-lg lg:h-[280px] lg:w-[300px] md:h-[260px] sm:h-[200px] h-[180px] w-[200px] overflow-hidden mx-auto"
                      src={game.imgurlnew}
                      alt={game.gamename}
                    />
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                      <Link to={`/games/${game.gameid}`}>
                        <button className="bg-gray-900 text-white lg:px-3 lg:py-3 px-2 py-2 rounded-lg font-semibold">
                          Play Now
                        </button>
                      </Link>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full bg-black/80 uppercase rounded-b lg:px-4 lg:py-4 px-1 py-2 text-center font-semibold text-white">
                      {game.gamename.slice(0,15)}
                    </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}


        <>

        <div className='divide'>
    <img src="/lidoma.png" alt="" className='logo' />
        <div className='lines'>
        <div className='line1'>

        </div>
        <div className='circle'></div>
        <div className='line2'>

        </div>
        </div>
    </div>

        <div className='h-[120rem]' >
   <iframe
        src="https://platform.lidoma.com/"
        title="Your Project"
        width="100%"
        height="100%"
       className='noscroll h-full border-none  '
      />
   </div>
        </>

       
      <Footer />
      </div>
    </div>
  );
}

export default HomePage;
